<template>
  <!-- 智慧园区 -->
  <div class="content-box">
    <div class="title-box">
      <div class="title-name">智慧小区</div>
      <div class="title-p">
        智慧小区是指充分利用互联网、物联网、大数据、云计算等新一代信息技术的集成应用，从与小区相关的法律法规、行业政策、物业信息、物业服务等方面，为小区居民提供安全、舒适、便利的现代化和智慧化的生活环境。
      </div>
    </div>
    <div class="function" style="background: #fff">
      <!-- <div class="function-title">功能场景</div> -->
      <div class="scenes-box">
        <div class="function-title">建设目标</div>
        <div class="scenes-bg">
          <div>
            <div class="leftTop">
              <img
                class="icons"
                src="../../assets/image/residentialQuartersImg/icon1.png"
                alt=""
              />
              安全
            </div>
            <div class="targetContent">
              通过对流动人员管理、出入人员管理等手段，安防感知设备、消防感知设备的建设，及时告警信息，小区、警、消防联动，保障小区安全。
            </div>
          </div>
          <div style="margin: 0 2%">
            <div class="leftTop">
              <img
                class="icons"
                src="../../assets/image/residentialQuartersImg/icon1.png"
                alt=""
              />
              展现
            </div>
            <div class="targetContent">
              建设统一管理平台，展现小区沙盘，通过图层叠加具象化小区实时情况，精确报警点位，给小区管理、物业、公安、社区、街道相关部门提供精确的数据服务。
            </div>
          </div>
          <div>
            <div class="leftTop">
              <img
                class="icons"
                src="../../assets/image/residentialQuartersImg/icon3.png"
                alt=""
              />
              服务
            </div>
            <div class="targetContent">
              建立访客管理、停车管理、物流管理手段，提供电动车充电、防盗服务，提供一体化养老、健康服务、信息化物业管理服务。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="function">
      <div class="function-title">平台架构</div>
      <div class="services">
        <div class="left"></div>
      </div>
    </div>
    <div class="function">
      <div class="function-title">功能场景</div>
    </div>
    <div class="scene">
      <h1></h1>
      <div class="img2" @click="go(1)">
        <div class="sceneBox">
          <div class="sceneIcon icon1"></div>
          <div class="sceneTitle">可视化大屏</div>
          <div class="sceneContent">
            平台可根据街道、社区、物业不同的职能范围进行首页定制，满足不同层次的管理需要，建立集政府监督、预警联动、智能分析、物业管理、便民服务等于一体的智慧社区业务管理平台，打造小事不出网格，大事不出村社的基层治理新样板。
          </div>
        </div>
      </div>
      <div class="img3" @click="go(2)">
        <div class="sceneBox">
          <div class="sceneIcon icon2"></div>
          <div class="sceneTitle">出入分析系统</div>
          <div class="sceneContent">
            <h5 style="text-align: center">人行管理</h5>
            通过物联设备，对出入口人员通道控制系统进行管理，使业主、租户刷脸进出小区。
            <h5 style="text-align: center">车行管理</h5>
            通过车牌识别摄像头一体机对进出车辆进行控制，分析进入车辆身份。结合地磁感应有效管理车位信息。
          </div>
        </div>
      </div>
      <div class="img4" @click="go(3)">
        <div class="sceneBox">
          <div class="sceneIcon icon3"></div>
          <div class="sceneTitle">视频信息管理系统</div>
          <div class="sceneContent">
            采用实景指挥系统，通过小区/社区高点图像，统筹全局，联动细节
          </div>
        </div>
      </div>
      <div class="img5" @click="go(4)">
        <div class="sceneBox">
          <div class="sceneIcon icon4"></div>
          <div class="sceneTitle">研判分析</div>
          <div class="sceneContent">
            以人、事、物、地址、组织等为要素，全面汇集整合小区各类信息资源，使用数据分析和挖掘技术，建立相应的实战应用模型，通过数据碰撞，挖掘出能够提示犯罪运行和变化趋势方向的情报信息，提高小区的安全度。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      console.log(scrollTop);
    },
    go(i) {
      if (i == 1) {
        this.$router.push("visualization");
      }
      if (i == 2) {
        this.$router.push("InAndOut");
      }
      if (i == 3) {
        this.$router.push("video");
      }
      if (i == 4) {
        this.$router.push("StudyAndJudge");
      }
    },
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped lang="scss">
.content-box {
  width: 100%;
  overflow: hidden;
  .title-box {
    width: 100%;
    color: #fff;
    height: 530px;
    padding: 0 160px;
    background: url("../../assets/image/residentialQuartersImg/bg1.png")
      no-repeat;
    background-size: 100% 100%;
    .title-name {
      font-size: 44px;
      font-weight: normal;
      color: #ffffff;
      transform: translateY(190px);
    }
    .title-p {
      width: 55%;
      font-size: 18px;
      font-weight: 300;
      line-height: 28px;
      transform: translateY(220px);
      color: #ffffff;
    }
  }
  .nh {
    background: url("../../assets/image/parkImg/nhbg.png") no-repeat;
    margin-top: 50px;
  }
  .function {
    padding: 0 10%;
    .function-title {
      text-align: center;
      padding: 30px 0;
      font-size: 40px;
      font-weight: 600;
      color: #333333;
    }

    .scenes-box {
      padding-bottom: 40px;
      .scenes-bg {
        width: 100%;
        height: 208px;
        display: flex;
        > div {
          position: relative;
          width: 32%;
          height: 100%;
          background: url("../../assets/image/residentialQuartersImg/bg2.png")
            no-repeat;
          background-size: 100%;
          .leftTop {
            width: 30%;
            height: 50px;
            line-height: 50px;
            text-align: center;
            color: #fff;
            border-bottom-right-radius: 25px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 600;
            background-color: #c7b090;
          }
          .targetContent {
            margin: 5% 6% 0;
            color: #6b6b6b;
            font-size: 16px;
          }
        }
        .icons {
          width: 20px;
          height: 20px;
          vertical-align: middle;
        }
      }
      .scenes-text {
        width: 100%;
        height: 400px;
        text-align: center;
        background-color: #fff;
        .title {
          font-size: 28px;
          font-weight: 600;
          padding: 20px 0;
          color: #333333;
        }
        .text-box {
          display: flex;
          .text-box-item {
            width: 16%;
            font-size: 16px;
            color: #6b6b6b;
            margin-top: 60px;
            border-right: 1px solid #dfdfdf;
            p {
              padding: 10px 0;
            }
          }
        }
      }
    }
    .services {
      width: 100%;
      height: 700px;
      display: flex;
      background-color: #fff;
      .left {
        width: 100%;
        height: 700px;
        background: url("../../assets/image/residentialQuartersImg/组 290.png")
          no-repeat;
        background-size: 100% 100%;
      }
      //   .right {
      //     width: 30%;
      //     padding: 6%;
      //     box-sizing: border-box;
      //     div {
      //       font-size: 24px;
      //       color: #6b6b6b;
      //     }
      //   }
    }
    .nh-text {
      font-size: 28px;
      margin: 20px 0;
      color: #6b6b6b;
    }
  }
  .function {
    padding: 0 10%;
    .function-title {
      text-align: center;
      padding: 30px 0;
      font-size: 40px;
      font-weight: 600;
      color: #333333;
    }
    .box1 {
      width: 100%;
      height: 400px;
      display: flex;
      background-color: #fff;
      .left {
        width: 50%;
        padding: 5% 10%;
        box-sizing: border-box;
        .left-title {
          font-size: 28px;
          font-weight: 600;
          padding: 30px 0;
          color: #333333;
        }
        .left-text {
          font-size: 16px;
          font-weight: 400;
          color: #6b6b6b;
        }
      }
      .right {
        width: 50%;
        height: 100%;
        // background: url("../../assets/image/buildingImg/img1.png") no-repeat;
      }
    }
    .box2 {
      width: 100%;
      height: 400px;
      display: flex;
      margin-top: 20px;
      background-color: #fff;
      .left {
        width: 50%;
        height: 100%;
        // background: url("../../assets/image/buildingImg/img2.png") no-repeat;
      }
      .right {
        width: 50%;
        padding: 7%;
        box-sizing: border-box;
        .right-title {
          font-size: 28px;
          font-weight: 600;
          padding: 30px 0;
          color: #333333;
        }
        .right-text {
          font-size: 16px;
          font-weight: 400;
          color: #6b6b6b;
        }
      }
    }
    .function-box {
      display: flex;
      justify-content: space-between;
      text-align: center;
      .function-item {
        width: 18.5%;
        height: 260px;
        // margin-right: 2%;
        background-color: #fff;
        img {
          width: 100px;
          height: 100px;
          margin: 30px 0 0 0;
        }
        div {
          font-size: 20px;
          font-weight: 600;
          margin: 22px 0;
          color: #333333;
        }
        p {
          width: 80%;
          margin: auto;
          font-size: 14px;
          font-weight: normal;
          color: #888888;
        }
      }
    }
    .device {
      width: 100%;
      height: 400px;
      padding: 10%;
      box-sizing: border-box;
      background: url("../../assets/image/buildingImg/bottom.png") no-repeat;
      .device-text {
        font-size: 28px;
        font-weight: 300;
        color: #ffffff;
      }
    }
  }
}
// .img3 {
//   background: url("../../assets/image/residentialQuartersImg/img3.png")
//     no-repeat;
//   background-size: 100% 100%;
// }
// .img4 {
//   background: url("../../assets/image/residentialQuartersImg/img4.png")
//     no-repeat;
//   background-size: 100% 100%;
// }
// .img5 {
//   background: url("../../assets/image/residentialQuartersImg/img5.png")
//     no-repeat;
//   background-size: 100% 100%;
// }
// .img6 {
//   background: url("../../assets/image/residentialQuartersImg/img6.png")
//     no-repeat;
//   background-size: 100% 100%;
// }
// .img7 {
//   background: url("../../assets/image/residentialQuartersImg/img7.png")
//     no-repeat;
//   background-size: 100% 100%;
// }
.scene {
  padding: 0 10%;
  display: flex;
  margin-bottom: 50px;
  overflow: hidden;
  > div {
    flex: 1;
    &:hover {
      transform: scale(1.07, 1.07);
    }
  }
  .sceneBox {
    cursor: pointer;
    &:hover {
      background: rgba(0, 0, 0, 0.8);
      opacity: 0.8;
      .sceneIcon {
        animation: moves 0.5s linear;
        animation-fill-mode: forwards;
      }

      .sceneContent {
        display: block;
      }
      @keyframes moves {
        0% {
        }
        100% {
          margin-top: 100px;
        }
      }
    }
    // flex: 1;
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding-top: 110px;
    box-sizing: border-box;
    .sceneIcon {
      margin-top: 180px;
      width: 44px;
      height: 44px;
      z-index: 99;
    }
    .sceneTitle {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #ffffff;
      margin: 5px 0;
      opacity: 1;
      z-index: 99;
      box-sizing: border-box;
      padding-bottom: 10px;
      border-bottom: 2px solid #fff;
    }
    .sceneContent {
      color: #fff;
      margin: 10px 40px;
      z-index: 99;
      display: none;
    }
  }
}
.img2 {
  background: url("../../assets/image/residentialQuartersImg/组 308.png")
    no-repeat;
  background-size: 100% 100%;
}
.img3 {
  background: url("../../assets/image/residentialQuartersImg/组 307.png")
    no-repeat;
  background-size: 100% 100%;
}
.img4 {
  background: url("../../assets/image/residentialQuartersImg/组 306.png")
    no-repeat;
  background-size: 100% 100%;
}
.img5 {
  background: url("../../assets/image/residentialQuartersImg/组 305.png")
    no-repeat;
  background-size: 100% 100%;
}
.icon1 {
  background: url("../../assets/image/residentialQuartersImg/sceneIcon1.png")
    no-repeat;
  background-size: 100% 100%;
}
.icon2 {
  background: url("../../assets/image/residentialQuartersImg/sceneIcon2.png")
    no-repeat;
  background-size: 100% 100%;
}
.icon3 {
  background: url("../../assets/image/residentialQuartersImg/sceneIcon3.png")
    no-repeat;
  background-size: 100% 100%;
}
.icon4 {
  background: url("../../assets/image/residentialQuartersImg/sceneIcon4.png")
    no-repeat;
  background-size: 100% 100%;
}
</style>
